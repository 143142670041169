<template>
    <v-dialog v-model="dialog" persistent max-width="680">
            <ValidationObserver v-slot="{ invalid }" ref="form">
              <v-card :key="key">
                <v-app-bar flat color="primary">
                  <v-toolbar-title class="text-h6 white--text pl-0">
                    {{ $t("anulate_order") }}
                  </v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="white"
                    icon
                    @click="$emit('close')"
                    :title="$t('Cancel')"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-app-bar>
                <v-card-text>
                <v-row class="pt-4">
                  <v-col cols="12" sm="12">
                    <ng-textarea-keyboard
                      v-model="item.reason"
                      :label="$t('reason')"
                      :rules="{ required: true }"
                    ></ng-textarea-keyboard>
                  </v-col>
                </v-row>
              </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    dark
                    outlined
                    color="primary"
                    @click="$emit('close')"
                    :title="$t('Cancel')"
                  >
                    {{ $t("Cancel") }}
                  </v-btn>
                  <v-btn
                    color="primary"
                    :disabled="invalid"
                    @click="annulOrder"
                    :title="$t('anulate_order')"
                  >
                    {{ $t("anulate_order") }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </ValidationObserver>
    </v-dialog>
</template>
<style>
.bg_modal {
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)) 0% 0% /
    cover no-repeat;
}
</style>
<script>
import NgTextareaKeyboard from '../../../gui/NgTextareaKeyboard.vue';
export default {
  components: { NgTextareaKeyboard },
  name: "CartAnnulOrder",
  data: () => ({
    searched: false,
    key: 0,
    item: { reason: "" },
  }),
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    order: Object,
    message: {
      type: String,
      default: "",
    },
  },

  mounted() {},
  methods: {
    annulOrder(order){

      this.$pos.getCurrentOrder().setCanceled(true)
      this.$pos.getCurrentOrder().setCancellationDate(this.$filters.datetime((new Date())))
      this.$pos.getCurrentOrder().setCancellationReason(this.item.reason)
      this.$pos.getCurrentOrder().setIsOpen(false)
      this.$pos.getCurrentOrder().setOrderStatus('annulled')
      this.$pos.notifyChangeInOrder();
      this.$pos.saveOrder(this.$pos.getCurrentOrder(), this.$http);

      this.$emit('annul', order);
    },
    cancel() {
      this.$emit("cancel");
    }

  },
};
</script>