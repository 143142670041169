<template>
    <v-dialog v-model="dialog" persistent max-width="680">        
            <ValidationObserver v-slot="{ invalid }" ref="form">
              <v-card :key="key">
                <v-app-bar flat color="primary">
                  <v-toolbar-title class="text-h6 white--text pl-0">
                    {{ $t("edit_tip") }}
                  </v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn color="white" icon @click="$emit('close')" :title="$t('Cancel')">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-app-bar>
                <v-card-text>
                <v-row class="pa-4">
                  <v-col cols="12" sm="12" class="pb-0">
                   <h4 class="font-weight-medium">{{ $t('select_calculate_tip')}}:                    </h4>
                  </v-col>
                  <v-col cols="12" sm="12" class="py-0">
                    <v-radio-group
                      v-model="automatic"
                      row
                    >
                      <v-radio
                        :label="$t('automatic')"
                        :value="true"
                      ></v-radio>
                      <v-radio
                      :label="$t('defined_client')"
                        :value="false"
                      ></v-radio>
                    </v-radio-group>                  
                  </v-col>
                  <v-col cols="12" sm="12" class="py-0">
                    <ng-number-keyboard v-if="!automatic"
                      v-model="tip"
                      :label="$t('tip')"
                      rules="required"
                    ></ng-number-keyboard>
                    <v-alert v-else text type="info" color="primary">
                      {{$t('automatic_percent_tip_msg')}} <strong class="font-weight-medium">{{$store.getters.getGeneralValue('gen_sales_tip_percent')}}%</strong> 
                    </v-alert>                    
                  </v-col>
                </v-row>
              </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn dark color="primary" outlined @click="$emit('close')" :title="$t('Cancel')">
                    {{ $t("Cancel") }}
                  </v-btn>
                  <v-btn color="primary"  :disabled="invalid" @click="setTip()" :title="$t('edit_tip')">
                    {{ $t("edit_tip") }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </ValidationObserver>
    </v-dialog>
</template>
<style>
.bg_modal{
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)) 0% 0% / cover no-repeat;
}
.v-input--radio-group.v-input--radio-group--row .v-radio.v-item--active{
  border:none !important;
}
</style>
<script>
import NgTextKeyboard from '../../../gui/NgTextKeyboard.vue';
const DEFAULT_ITEM = { reason: "" };
export default {
  components: { NgTextKeyboard },
  name: "CartTip",
  data: () => ({
    searched: false,
    key: 0,
    tip: 0,
    automatic: true
  }),
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    order: Object 
  },
  watch: {
    "$store.getters.orderChanged": function () {
      this.tip = this.$pos.getCurrentOrder().getPriceTip(); 
    }
  },
  mounted() {
    this.tip = this.$pos.getCurrentOrder().getPriceTip();
  },
  methods: {
    cancel() {
      this.clear();
      this.searched = false;
      this.key++;
      this.$emit("cancel");
    },
    clear() {
      this.item = JSON.parse(JSON.stringify(DEFAULT_ITEM));
    },
    setTip(){  
      if(this.automatic){
        this.$pos.setPriceTip(this.order, null);
      } else {
        this.$pos.setPriceTip(this.order, this.tip);
      }      
      this.$emit("cancel");
    }
  },
};
</script>