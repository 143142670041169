<template>
  <div>
    <span class="subtitle-1 font-weight-bold">{{ option.name }}</span>
    <div v-if="option.option_type == 'products'">
      <v-list-item-group :multiple="option.max_options > 1" v-model="optionsItem" color="primary"
        class="d-flex variant_flex_flow" :max="option.max_options">
        <template v-for="(pr, i) in getProducts(option.products)">
          <v-list-item :value="pr.id" :key="i" @click="selectOption({ value: pr.id }, option)"
            style="border: 1px solid #ccc; padding: 0 10px !important;" class="cart-selection-variant-option 1">
            <!-- :disabled="isDisabledProduct(option, pr)" -->
            <template v-slot:default="{ active }">
              <v-list-item-content style="padding:5px 0 !important;">
                <span class="text-left">{{ pr.name }}</span>

                <span class="text-left" v-if="option.apply_price">{{
                  $filters.currency($pos.getSalePrice(pr, 0, currentList))
                }}</span>
              </v-list-item-content>
              <v-list-item-action style="margin:0 !important;">
                <v-icon v-if="!active" color="grey lighten-1">
                  mdi-check-circle-outline
                </v-icon>

                <v-icon v-else color="green darken-3">
                  mdi-check-circle
                </v-icon>
              </v-list-item-action>
            </template>
          </v-list-item>
        </template>
      </v-list-item-group>
    </div>

    <div v-if="option.option_type == 'custom'">
      <v-list-item-group :multiple="option.max_options > 1" v-model="optionsItem" color="primary"
        class="d-flex variant_flex_flow" :max="option.max_options">
        <template v-for="(item, i) in option.sale_options">
          <v-list-item :value="item.name" :key="i" @click="selectOption({ value: item.name }, option)"
            style="border: 1px solid #ccc; padding: 0 10px !important;" class="cart-selection-variant-option 2">
            <!--  :disabled="isDisabledOption(option, item)" -->
            <template v-slot:default="{ active }">
              <span class="text-left">{{ item.name }}</span>
              <v-list-item-action style="margin:0 !important;">
                <v-icon v-if="!active" color="grey lighten-1">
                  mdi-check-circle-outline
                </v-icon>

                <v-icon v-else color="green darken-3">
                  mdi-check-circle
                </v-icon>
              </v-list-item-action>
            </template>
          </v-list-item>
        </template>
      </v-list-item-group>
    </div>
    <div v-if="option.option_type == 'category'">
      <!--  :disabled="isDisabledProduct(option, pr)" -->
      <v-list-item-group :multiple="option.max_options > 1" v-model="optionsItem" color="primary"
        class="d-flex variant_flex_flow" :max="option.max_options">
        <template v-for="(pr, i) in getProductsByCategory(option.inv_category_id)">
          <v-list-item :value="pr.id" :key="i" @click="selectOption({ value: pr.id }, option)"
            style="border: 1px solid #ccc; padding: 0 10px !important;" class="cart-selection-variant-option 3">
            <template v-slot:default="{ active }">
              <v-list-item-content style="padding:5px 0 !important;">
                <span class="text-left">{{ pr.name }}</span>

                <span class="text-left" v-if="option.apply_price">{{
                  $filters.currency($pos.getSalePrice(pr, 0, currentList))
                }}</span>
              </v-list-item-content>
              <v-list-item-action style="margin:0 !important;">
                <v-icon v-if="!active" color="grey lighten-1">
                  mdi-check-circle-outline
                </v-icon>

                <v-icon v-else color="green darken-3">
                  mdi-check-circle
                </v-icon>
              </v-list-item-action>
            </template>
          </v-list-item>
        </template>
      </v-list-item-group>
    </div>
  </div>
</template>
<script>
export default {
  name: "CartSectionProductSaleOptions",
  props: ["itemLine", /*'product',*/ "option", "currentList"],
  data: () => ({
    //prices: null
    //isVisible: false
    //options: null,
    optionsItem: null,
    key: 0,
  }),
  mounted() {
    //this.loadConfig();
    if (this.option.max_options > 1) {
      this.optionsItem = [];
    }
    if (this.itemLine) {
      if (this.itemLine.options && this.itemLine.options[this.option.id]) {
        if (this.option.option_type == "custom") {
          this.optionsItem = this.itemLine.options[this.option.id].value;
        } else {
          this.optionsItem = this.itemLine.options[this.option.id].value;
        }
      }
    }
    this.checkConstrains();
  },
  beforeUpdate() {
    // this.key++;
    this.checkConstrains();
  },
  methods: {

    getProducts(prds) {
      const ids = prds.map((prd) => prd.id);
      return this.$db.searchProducts({ id: { $in: ids } }, 50, "name");
    },
    selectOption(option, opt) {
      //if(this.option.min_options > 0 && (!this.optionsItem || (this.optionsItem.length < this.option.min_options))){
      //if(this.option.min_options > 0 && (this.optionsItem && Array.isArray(this.optionsItem) && this.optionsItem.length < this.option.min_options)){

      // }else{
      setTimeout(() => {
        this.$emit("selectedOption", {
          option: { value: this.optionsItem },
          id: opt.id,
        });
        this.checkConstrains();
      }, 200);
      // }
    },
    checkConstrains() {
      if (
        this.option.min_options > 0 &&
        (!this.optionsItem || this.optionsItem.length < this.option.min_options)
      ) {
        this.$root.$emit("error", {
          field: this.option.id,
          message:
            "Debe seleccionar al menos una opción de: " + this.option.name,
        });
      } else {
        this.$root.$emit("valid", { field: this.option.id });
      }
    },
    getProductsByCategory(catId) {
      const prds = this.$db.searchProducts(
        {
          category_id: { $eq: catId },
        },
        30,
        "name"
      );
      return prds;
    },
  },
};
</script>
<style >
.v-item--active {
  border: 2px solid rgb(0, 100, 150) !important;
  font-size: 1em !important;
}

.cart-selection-variant-option {
  border: 1px solid #ccc;
  max-width: 25%;
}

.variant_flex_flow {
  display: flex;
  flex-flow: row wrap;
}
</style>