<template>
  <div>
    <v-card class="ma-1" outlined @click="selectProduct" :disabled="!hasPrice()"
      :title="'REF:' + item.reference + ' - ' + item.name + ' - ' + $filters.currency($pos.getSalePrice(this.item, 0, getPriceListId()), 0)">
      <v-list-item class="text-center">

        <v-row
        class="fill-height"
      >
        <v-col
          class=""
          cols="5"
        >
          <v-list-item-avatar tile size="94" color="white" class="mx-auto rounded-lg">
            <v-img height="100%" :src="getPathProduct(item)"></v-img>
          </v-list-item-avatar>          

      </v-col>
      <v-col cols="7" class="pt-2">
        <v-list-item-content class="text-left">
          <v-list-item-title class="grey--text text--darken-1 pt-3 mt-2">
        Referencia</v-list-item-title>
        <v-list-item-subtitle class="text-left"><v-chip small color="primary" class="">{{ item.reference
          }}</v-chip></v-list-item-subtitle>
      </v-list-item-content>
        

      </v-col>
      </v-row>
              
      </v-list-item>
      <v-list-item>
        <v-list-item-content class="text-center pt-0">         
          <v-list-item-title class="grey--text text--darken-1">{{ item.name }}</v-list-item-title>
          <v-list-item-subtitle class="text-h6 green--text text--darken-3">{{
            $filters.currency($pos.getSalePrice(this.item, 0,
              getPriceListId()), 0)
          }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-card-actions class="pt-0">
        <v-btn block color="green" dark class="caption"><v-icon left>mdi-basket-plus-outline</v-icon>Añadir al pedido</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>

export default {
  name: 'ProductCard',
  components: {},
  data: () => ({
    prices: null
    //isVisible: false
  }),
  props: ['item', 'priceList'],
  /* mounted(){
   },	*/
  methods: {
    selectProduct() {
      if (this.hasPrice()) {
        this.$emit('select:product', this.item)
      }
    },
    hasPrice() {
      return this.$pos.getSalePrice(this.item, 0, this.getPriceListId()) > 0;
    },
    getPriceListId() {
      if (this.priceList) {
        return this.priceList.id
      }
      return null
    },
    getPathProduct(item) {
      if (item && this.$store.state.shared && this.$store.state.shared.company) {
        //return this.$http.getBaseUrlImagesProducts() + this.$store.state.shared.company.id + '/' + item.image;
        if (item.image) {
          return this.$http.getBaseUrlImagesProducts() + '' + item.image;
        }
      }
      return '/assets/product-bag-shade.png';
    },
    containsImage(item){
      if (item && this.$store.state.shared && this.$store.state.shared.company) {
        if (item.image) {
          return true;
        }
      }
      return false;
    }
    /*visibilityChanged(isVisible, entry){
      this.isVisible = isVisible
    }*/
  }
}
</script>