<template>
  <div>
    <v-dialog v-model="modalErrorSaveOrder" persistent max-width="850">
      <v-card>
        <v-card-title>Se ha presentado el siguiente error: "{{ errorSaveOrder }}".</v-card-title>
        <v-card-actions>
          <!--v-switch prepend-icon="mdi-printer" v-model="printReceiptSwitch" inset :label="printerOption()"></v-switch-->
          <v-spacer></v-spacer>
          <v-btn dark color="primary" outlined @click="closeModalErrorSaveOrder()" :title="$t('Aceptar')">
            {{ $t("Aceptar") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog" persistent max-width="850">
      <v-card :key="key" class="overflow-hidden">
        <v-app-bar flat color="white">
          <v-toolbar-title class="text-h6 grey--text pl-0">
            {{ $t("total_to_pay") }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-title class="text-h5 grey--text pl-0">
            {{ $filters.currency(this.order.getPriceTotalWithAdds(), 0) }}
          </v-toolbar-title>
          <v-btn color="black" icon @click="cancel()" :title="$t('Cancel')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-app-bar>

        <v-card-text class="pa-0">
          <v-row no-gutters>
            <v-col cols="12" lg="6" class="green">
              <v-row class="pa-3 white--text">
                <v-col class="text-left">
                  <span class="title font-weight-medium">{{ $t("paid") }}:
                  </span>
                </v-col>
                <v-col class="text-right">
                  <span class="title font-weight-regular">
                    {{
                      $filters.currency(
                        this.order.getTotalPayments(),
                        0
                      )
                    }}
                  </span>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" lg="6" class="red lighten-2">
              <v-row class="pa-3 white--text">
                <v-col class="text-left">
                  <span class="title font-weight-medium">{{ $t("balance") }}:
                  </span>
                </v-col>
                <v-col class="text-right">
                  <span class="title font-weight-regular">
                    {{ $filters.currency(this.order.getBalance(), 0) }}
                  </span>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-text class="pa-1">
          <v-tabs :vertical="!is_mobile" show-arrows>
            <v-tab v-for="pay in paymentMethods" :key="pay.id" class="font-weight-medium body-2">
              {{ pay.name }}
            </v-tab>
            <v-tab class="font-weight-medium body-2">
              <v-badge color="green" inline :content="countPayments()" :value="countPayments()">
                {{ $t("payment_list") }}
              </v-badge>
            </v-tab>

            <v-tab-item v-for="pay in paymentMethods" :key="pay.id">
              <v-card flat>
                <CartPaymentsKeyboard :paymentMethod="pay" :order="order" :is_mobile="is_mobile"></CartPaymentsKeyboard>
              </v-card>
            </v-tab-item>
            <v-tab-item class="pa-1">
              <v-container>
                <v-card>
                  <v-card-text class="pa-0">
                    <v-data-table :headers="headers" :items="order.getPayments()" sort-by="_id"
                      class="contact-listing-app">
                      <!-- eslint-disable-next-line -->
                      <template v-slot:item.payment_method="{ item }">
                        {{ item.payment_method.name }}
                      </template>
                      <!-- eslint-disable-next-line -->
                      <template v-slot:item.value="{ item }">
                        {{ $filters.currency(item.value, 0) }}
                      </template>
                      <!-- eslint-disable-next-line -->
                      <template v-slot:item.date="{ item }">
                        {{ $filters.date(item.date, "DD-MMM-YYYY") }}
                      </template>
                      <!-- eslint-disable-next-line -->
                      <template v-slot:item.acc_income_id="{ item }">
                        <v-icon class="green--text" v-if="item.acc_income_id" :title="$t('synchronized')">
                          mdi-sync
                        </v-icon>
                        <v-icon class="red--text" :title="$t('no_synchronized')" v-else>
                          mdi-sync-alert
                        </v-icon>
                      </template>
                      <!-- eslint-disable-next-line -->
                      <template v-slot:item.actions="{ item }">
                        <v-btn icon dark v-if="!item.acc_income_id" :title="$t('delete')" @click="deletePayment(item)">
                          <v-icon color="red">mdi-delete</v-icon>
                        </v-btn>
                      </template>
                      <template v-slot:no-data>
                        {{ $t("no_items") }}
                      </template>
                    </v-data-table>
                  </v-card-text>
                </v-card>
              </v-container>
            </v-tab-item>
          </v-tabs>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-switch prepend-icon="mdi-printer" v-model="printReceiptSwitch" inset :label="printerOption()"></v-switch>
          <v-spacer></v-spacer>
          <v-btn dark color="primary" outlined @click="cancel()" :title="$t('Cancel')">
            {{ $t("Cancel") }}
          </v-btn>
          <v-btn v-if="!orderFinalized" color="primary" :disabled="this.order.getBalance() > 0" @click="toPay()"
            :title="$t('to_pay')">
            {{ $t("to_pay") }}
          </v-btn>
          <v-btn v-if="orderFinalized" color="primary" @click="printReceipt()" :title="$t('print_receipt')">
            {{ $t("print_receipt") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import CartPaymentsKeyboard from "./CartPaymentsKeyboard";
import MixinConfirm from "./../../../mixins/MixinConfirm";

export default {
  name: "CartPayments",
  components: { CartPaymentsKeyboard },
  mixins: [MixinConfirm],
  data: () => ({
    orderFinalized: false,
    searched: false,
    invalid: true,
    printReceiptSwitch: true,
    key: 0,
    paymentMethods: [],
    dialogDelete: false,
    headers: [],
    modalErrorSaveOrder: false,
    errorSaveOrder: ''
  }),
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    order: Object,
    is_mobile: Boolean,
  },
  //props: ['customer'],

  mounted() {
    /*if(this.customer){
      setTimeout(() => {


      this.item = JSON.parse(JSON.stringify(this.customer));
      this.searched = true;
      this.key++;
      }, 250)
    }*/
    this.getPaymentMethods();
    this.getValuePrintAutomatic();

    this.headers = [
      { text: this.$t("name"), value: "payment_method" },
      { text: this.$t("value"), value: "value" },
      { text: this.$t("date"), value: "date" },
      { text: this.$t("status"), value: "acc_income_id", sortable: false },
      { text: this.$t("actions"), value: "actions", sortable: false },
    ];
  },
  methods: {
    async getValuePrintAutomatic(){
      let installation = this.$store.getters.installation;		
      if (installation) {
        const cashRegister = await this.$db.getCashRegister(
          installation.cash_id
        );
        if(cashRegister){
          if(cashRegister.config){
            if(cashRegister.config.pos_active_print_receipt_automatic){
              this.printReceiptSwitch = cashRegister.config.pos_active_print_receipt_automatic;
            }
          }
        }
      }

    },
    async toPay() {


      try {

        this.orderFinalized = true;
        let order = this.$pos.getCurrentOrder();
        // se modifica para que la orden pueda salir en las pantallas de cocina
        // si esta activo
        if (order.order_status == 'created') {
          this.$pos.setOrderStatus(this.$pos.getCurrentOrder(), "received");
        } else {
          // si se realiza el pago desde cualquier otro estado se cierra la orden
          this.$pos.setOrderStatus(this.$pos.getCurrentOrder(), "closed");
        }


        this.$pos.setIsOpen(this.$pos.getCurrentOrder(), false);
        this.$pos.setClosingDate(
          this.$pos.getCurrentOrder(),
          this.$filters.datetime(new Date())
        );
        let response = await this.$pos.saveOrder(
          this.$pos.getCurrentOrder(),
          this.$http,
          true
        );
        if (response.success) {          
          if(response.item){
            if(response.item.invoice_id){
              this.$pos.setInvoiceId(this.$pos.getCurrentOrder(), response.item.invoice_id);
            }
          }          
          if (this.printReceiptSwitch) {
            // this.printReceipt();
            this.$pos.printOrderServer(response.item);
          }
        } else {
          // alert(response.msg);
          this.errorSaveOrder = response.msg;
          this.showModalErrorSaveOrder();
        }

        //this.$pos.syncOrder
        // (this.$pos.getCurrentOrder())
        /* */
        /*if (this.$pos.getCurrentOrder().getIsOnTable()) {
          let name_table = this.$pos.getCurrentOrder().getNameTable();
          if (name_table) {
            let table = await this.$db.getTableByName(name_table);
            await this.$db.updateTableBusy(table, false);
          }
        }*/


      } catch (e) {
        alert(e);
        console.error(e);
        return null;
      }


      /*
      const obj = JSON.parse(JSON.stringify(this.item));
      this.clear();
      this.searched = false;  		
      this.$emit('selected', obj);
      this.key++;*/
      // this.paidOut = true;
    },
    async cancel() {
      this.searched = false;
      this.key++;
      this.getValuePrintAutomatic();
      this.$emit("cancel", { orderfinalized: this.orderFinalized });
      this.clear();
    },
    cancelError() {
      this.searched = false;
      this.key++;
      this.getValuePrintAutomatic();
      this.$emit("cancelError");
      this.clear();
    },    
    clear() {
      if (this.orderFinalized) {
        this.orderFinalized = false;
      }
    },
    countPayments() {
      return this.order.getPayments().length;
    },
    deletePayment(payment) {
      this.openConfirm(this.$t("are_you_sure_delete_this_item")).then(
        (response) => {
          if (response) {
            this.$pos.deletePayment(payment);
          }
        }
      );
    },
    searchClient() {
      if (this.item.identification) {
        this.$http
          .get(
            "api/v1/list/client-by-document",
            { name: this.item.identification },
            true,
            false
          )
          .then((response) => {
            if (response.success) {
              if (response.items && response.items.length == 1) {
                this.item = response.items[0];
              }
            }
            this.searched = true;
          })
          .catch((e) => {
            this.searched = true;
          });
      }
    },
    async getPaymentMethods() {
      const payment_methods = await this.$db.getPaymentMethods();
      this.paymentMethods = payment_methods;
    },
    printerOption() {
      if (this.printReceiptSwitch) {
        return this.$t("print");
      }
      return this.$t("no_print");
    },
    print() { },
    printReceipt() {
      this.$pos.printReceipt(this.$pos.getCurrentOrder());
    },
    showModalErrorSaveOrder() {
      this.modalErrorSaveOrder = true;
    },
    closeModalErrorSaveOrder() {
      this.modalErrorSaveOrder = false;
      this.cancelError();
    }
  },
};
</script>
<style>
.grid_ss {
  border-left: 4px solid var(--v-secondary-base) !important;
}
</style>