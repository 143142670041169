<template>
  <v-dialog v-model="dialog" persistent max-width="680">
    <ValidationObserver v-slot="{ invalid }" ref="form">
      <v-card v-if="config" :key="key">
        <v-app-bar flat color="primary">
          <v-toolbar-title class="text-h6 white--text pl-0">
            {{ $t("customer") }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="white" icon @click="cancel" :title="$t('Cancel')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-app-bar>

        <v-card-text>
          <v-row class="px-2 py-4 mb-3 pb-0">
            <v-col
              cols="12"
              class="py-2 d-flex flex-column align-center justify-center"
            >
              <h2 class="mb-0 mb-lg-3 font-weight-medium">Buscar por</h2>
              <span class="d-lg-none d-block mb-3"
                >Número de identificacion o Celular</span
              >

              <v-btn-toggle
                v-model="searchType"
                tile
                color="primary accent-3"
                mandatory
              >
                <v-btn value="identification">
                  <span class="d-none d-lg-block">
                    <v-icon left color="blue">mdi-card-account-details</v-icon
                    >Número de Identificación
                  </span>

                  <span class="d-lg-none d-block">
                    <v-icon color="blue">mdi-card-account-details</v-icon>
                  </span>
                </v-btn>

                <v-btn value="phone">
                  <span class="d-none d-lg-block">
                    <v-icon left color="blue">mdi-card-account-phone</v-icon
                    >Celular
                  </span>

                  <span class="d-lg-none d-block">
                    <v-icon color="blue">mdi-card-account-phone</v-icon>
                  </span>
                </v-btn>
              </v-btn-toggle>
            </v-col>

            <v-col
              cols="12"
              lg="9"
              class="pb-0"
              v-if="searchType == 'identification'"
            >
              <ng-number-keyboard
                :filled="false"
                :dense="true"
                v-model="identification"
                :label="$t('identification')"
                @keyup.enter="searchClient"
                @clear="clearSearch"
                hide-details
              ></ng-number-keyboard>
            </v-col>
            <v-col
              cols="12"
              lg="3"
              class="mt-0"
              v-if="searchType == 'identification'"
            >
              <v-btn
                block
                color="primary"
                :disabled="!identification"
                @click="searchClient"
                :title="$t('Search')"
              >
                <v-icon>mdi-magnify</v-icon> {{ $t("Search") }}
              </v-btn>
            </v-col>

            <v-col cols="12" lg="9" class="pb-0" v-if="searchType == 'phone'">
              <ng-number-keyboard
                :filled="false"
                :dense="true"
                v-model="phone"
                :label="$t('phone')"
                @keyup.enter="searchClient"
                @clear="clearSearch"
                hide-details
              ></ng-number-keyboard>
            </v-col>
            <v-col cols="12" lg="3" class="mt-0" v-if="searchType == 'phone'">
              <v-btn
                block
                color="primary"
                :disabled="!phone"
                @click="searchClient"
                :title="$t('Search')"
              >
                <v-icon>mdi-magnify</v-icon> {{ $t("Search") }}
              </v-btn>
            </v-col>
          </v-row>
          <v-divider />
          <v-row v-if="searched" class="pa-2 my-0 mt-3 pb-0">
            <!-- {{item}} -->
            <v-col cols="12" sm="6" class="py-0">
              <ng-text-keyboard
                :filled="false"
                :dense="true"
                v-model="item.first_name"
                :label="$t('first_name')"
                :rules="{ required: true }"
              ></ng-text-keyboard>
            </v-col>
            <v-col cols="12" sm="6" class="py-0" v-if="requireLastName()">
              <ng-text-keyboard
                :filled="false"
                :dense="true"
                v-model="item.last_name"
                :label="$t('last_name')"
                :rules="{ required: true }"
              ></ng-text-keyboard>
            </v-col>
            <v-col cols="12" sm="6" class="py-0">
              <ng-select
                v-model="item.type_identification"
                label="type_identification"
                rules="required"
                url="api/v1/list/type-identification"
              ></ng-select>
            </v-col>
            <v-col cols="12" sm="6" class="py-0">
              <ng-text
                v-model="item.identification"
                label="identification"
                rules="required"
              ></ng-text>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              class="py-0"
              v-if="requireVerificationDigit()"
            >
              <ng-integer
                v-model="item.verification_digit"
                label="verification_digit"
                style="width: 49%"
              ></ng-integer>
            </v-col>
            <v-col cols="12" sm="6" class="py-0">
              <ng-phone-keyboard
                :filled="false"
                :dense="true"
                v-model="item.phone"
                :label="$t('phone')"
                :rules="{ required: true }"
              ></ng-phone-keyboard>
            </v-col>
            <v-col cols="12" sm="6" class="py-0">
              <ng-text-keyboard
                :filled="false"
                :dense="true"
                v-model="item.address"
                :label="$t('address')"
                :rules="{ required: true }"
              ></ng-text-keyboard>
            </v-col>
            <v-col cols="12" sm="6" class="py-0"
            v-if="isInvoiceElectronically()"
            >
              <ng-email-keyboard
                :filled="false"
                :dense="true"
                v-model="item.email"
                :label="$t('email')"
              ></ng-email-keyboard>
            </v-col>

            <v-col cols="12" sm="6" class="py-0"
            v-if="isInvoiceElectronically()"
            >
              <ng-select
                v-model="item.country"
                label="country"
                rules="required"
                @change="$refs.region.reload()"
                url="api/v1/list/country"
              ></ng-select>
            </v-col>
            <v-col cols="12" sm="6" class="py-0"
            v-if="isInvoiceElectronically()"
            >
              <ng-select
                v-model="item.region"
                label="region"
                rules="required"
                :not-auto-load="true"
                :depends-of="{ country: item.country }"
                :cache-off="true"
                ref="region"
                @change="$refs.city.reload()"
                url="api/v1/list/region"
              ></ng-select>
            </v-col>
            <v-col cols="12" sm="6" class="py-0"
            v-if="isInvoiceElectronically()"
            >
              <ng-select
                v-model="item.city"
                label="city"
                rules="required"
                :not-auto-load="true"
                :depends-of="{ region: item.region }"
                :cache-off="true"
                ref="city"
                url="api/v1/list/city"
              ></ng-select>
            </v-col>

            <v-col cols="12" sm="6" class="py-0"
            v-if="isInvoiceElectronically()"
            >
              <ng-select
                v-model="item.type_organization"
                label="type_organization"
                rules="required"
                url="api/v1/list/type-organization"
              ></ng-select>
            </v-col>
            <v-col cols="12" sm="6" class="py-0"
            v-if="isInvoiceElectronically()"
            >
              <ng-select
                v-model="item.type_regimes"
                label="type_regimes"
                rules="required"
                url="api/v1/list/type-regimes"
              ></ng-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            dark
            color="grey"
            outlined
            @click="cancel"
            :title="$t('Cancel')"
          >
            {{ $t("Cancel") }}
          </v-btn>
          <v-btn
            v-if="searched"
            color="primary"
            :disabled="invalid"
            @click="selected"
            :title="$t('select_customer')"
          >
            {{ $t("select_customer") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </ValidationObserver>
  </v-dialog>
</template>
<script>
const DEFAULT_ITEM = {
  id: "",
  name: "",
  last_name: "",
  email: "",
  identification: "",
  phone: "",
  address: "",
  country: null,
  region: null,
  city: null,
  type_identification: null,
  identification: null,
  verification_digit: null,
  type_organization: null,
  type_regimes: null,
};
export default {
  components: {},
  name: "CartCustomer",
  data: () => ({
    searched: false,
    key: 0,
    searchType: "identification",
    item: JSON.parse(JSON.stringify(DEFAULT_ITEM)),
    config: {
      first_name: {
        name: "first_name",
        label: "Nombres",
        required: true,
        validate: ["required"],
      },
      last_name: {
        name: "last_name",
        label: "Apellidos",
        required: true,
        validate: ["required"],
      },
      identification: {
        name: "identification",
        label: "Numero Identificación",
        required: false,
        validate: [],
      },
      email: { name: "email", label: "Email", required: false, validate: [] },
      phone: {
        name: "phone",
        label: "Celular",
        required: true,
        validate: ["required"],
      },
      address: {
        name: "address",
        label: "Dirección",
        required: false,
        validate: [],
      },
    },
    order: null,
    phone: "",
    identification: "",
  }),
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  //props: ['customer'],
  watch: {
    dialog(newVal, oldVal) {
      //this.setDefaultClient();
      this.setDefaultClient();
    }
  },
  mounted() {
    this.setDefaultClient();
    /*if(this.customer){
      setTimeout(() => {


      this.item = JSON.parse(JSON.stringify(this.customer));
      this.searched = true;
      this.key++;
      }, 250)
    }*/
  },
  methods: {
    requireLastName() {
      return (
        this.item &&
        this.item.type_identification &&
        this.item.type_identification.requires_last_name
      );
    },
    requireVerificationDigit() {
      return (
        this.item &&
        this.item.type_identification &&
        !this.item.type_identification.requires_last_name
      );
    },
    selected() {
      const obj = JSON.parse(JSON.stringify(this.item));
      this.clear();
      this.clearSearch();
      this.searched = false;
      this.$emit("selected", obj);
      this.key++;
    },
    cancel() {
      this.clear();
      this.clearSearch();
      this.searched = false;
      if (this.$pos.getCurrentOrder().getBusPersonCustomerId() == null) {
        //this.searched = false;
      }
      this.key++;
      this.$emit("cancel");
    },
    clear() {
      this.item = JSON.parse(JSON.stringify(DEFAULT_ITEM));
    },
    searchClient() {
      if (this.identification || this.phone) {
        if (this.identification != "" && this.searchType == "identification") {
          let doc = this.identification;
          this.$http
            .get(
              "api/v1/list/client-by-document",
              { name: this.identification },
              true,
              false
            )
            .then((response) => {
              if (response.success) {
                if (response.items && response.items.length > 0) {
                  this.item = response.items[0];
                } else {
                  this.item = { identification: doc };
                }
              }
              this.searched = true;
            })
            .catch((e) => {
              this.searched = true;
            });
        }
        if (this.phone != "" && this.searchType == "phone") {
          let phone = this.phone;
          this.$http
            .get(
              "api/v1/list/client-by-phone",
              { name: this.phone },
              //{ name: this.item.identification },
              true,
              false
            )
            .then((response) => {
              if (response.success) {
                if (response.items && response.items.length > 0) {
                  this.item = response.items[0];
                } else {
                  this.item = { phone: phone };
                }
              }
              this.searched = true;
            })
            .catch((e) => {
              this.searched = true;
            });
        }
      } else {
        this.item = { identification: "" };
        this.phone = "";
        this.searched = false;
      }
    },
    isInvoiceElectronically() {
      return this.$store.getters.getGeneralValue(
        "gen_billing_invoice_electronically"
      );
    },
    clearSearch() {
      this.item = {};
      this.phone = "";
      this.identification = "";
      this.searched = false;
    },
    setDefaultClient() {
      if (this.$pos.getCurrentOrder().getBusPersonCustomerId()) {
        this.searched = true;
        this.item = this.$pos.getCurrentOrder().getDataCustomer();
      } else {
        this.searched = false;
        this.item = JSON.parse(JSON.stringify(DEFAULT_ITEM));
      }
    },
  },
};
</script>