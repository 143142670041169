<template>
    <div>

        <div class="py-2 mt-4 pl-3 pr-3">
            <ValidationObserver v-slot="{ invalid }" ref="form">
                <v-card>
                    <v-row>
                        <v-col cols="12">
                            <v-card flat>
                                <v-card-title class="justify-space-between">
                                    Productos
                                    <v-btn color="red" @click="$emit('close')" icon><v-icon>mdi-close</v-icon></v-btn>
                                </v-card-title>
                                <v-divider></v-divider>
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-card flat>
                                                <v-card-subtitle class="black--text">Filtros</v-card-subtitle>
                                                <v-card-text>
                                                    <v-row>
                                                        <v-col cols="3">
                                                            <ng-text label="EAN" v-model="filters.ean"
                                                                hide-details></ng-text>
                                                        </v-col>
                                                        <v-col cols="3">
                                                            <ng-text label="Nombre" v-model="filters.name"
                                                                hide-details></ng-text>
                                                        </v-col>
                                                        <v-col cols="3">
                                                            <ng-text label="Proveedor" v-model="filters.provider_name"
                                                                hide-details></ng-text>
                                                        </v-col>
                                                        <v-col cols="3">
                                                            <v-btn class="ml-1" color="primary"
                                                                @click="getMedications()">
                                                                <v-icon>mdi-magnify</v-icon>
                                                                Consultar
                                                            </v-btn>
                                                            <v-btn class="ml-1" color="primary" @click="clear()">
                                                                <!--v-icon>mdi-magnify</v-icon-->
                                                                Limpiar
                                                            </v-btn>
                                                            
                                                        </v-col>
                                                        <v-col cols="3">
                                                            <ng-text label="Palabra clave" v-model="filters.key_word"
                                                                hide-details></ng-text>
                                                        </v-col>
                                                    </v-row>
                                                </v-card-text>
                                            </v-card>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-card flat>
                                                <v-simple-table fixed-header height="450px">
                                                    <template v-slot:default>
                                                        <thead>
                                                            <tr>
                                                                <th class="text-left">Acciones</th>
                                                                <th class="text-left">{{ $t("ean") }}</th>
                                                                <th class="text-left">
                                                                    {{ $t("product_name") }}
                                                                </th>
                                                                <th class="text-left">
                                                                    {{ $t("provider_name") }}
                                                                </th>
                                                                <th class="text-left">
                                                                    {{ $t("active_ingredient") }}
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="item in medications" :key="item.id">
                                                                <td>
                                                                    <v-btn icon small color="primary"
                                                                        title="Visualizar Detalles Completos" @click="
                                                                            $refs.showInfoMedication.renderItem(item)
                                                                            ">
                                                                        <v-icon>mdi-magnify</v-icon>
                                                                    </v-btn>

                                                                    <v-btn icon small color="primary"
                                                                        title="Añadir" @click="
                                                                            addItem(item)
                                                                            ">
                                                                        <v-icon>mdi-plus</v-icon>
                                                                    </v-btn>

                                                                </td>
                                                                <td>{{ item.info_medication.ean }}</td>
                                                                <td>{{ item.info_medication.name }}</td>
                                                                <td>{{ item.info_medication.provider_name }}</td>
                                                                <td>{{ item.info_medication.active_ingredient }}</td>
                                                            </tr>
                                                        </tbody>
                                                    </template>
                                                </v-simple-table>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>


                    </v-row>
                </v-card>
            </ValidationObserver>
        </div>
        <ShowInfoMedication ref="showInfoMedication" @add:item="addItem" />
    </div>
</template>
<script>
import ShowInfoMedication from "./ShowInfoMedication.vue";
export default {
    components: { ShowInfoMedication },
    data() {
        return {
            title: "Importar Medicamentos",
            breadcrumbs: [],
            tutorial: null,
            help: null,
            medications: [],
            importItems: [],
            filters: {},
        };
    },
    async mounted() {
        await this.getMedications();
    },
    methods: {
        async clear() {
            this.filters = {};
            await this.getMedications();
        },
        async getMedications() {
            try {

                this.medications = await this.$db.getAllMedications(this.filters);
                console.log(this.medications)
                /*this.medications = [];
                const res = await this.$http.get(
                  "api/v1/list/medications",
                  this.filters,
                  true,
                  true
                );
                if (res.success) {
                  this.medications = res.items;
                }*/
            } catch (e) {
                console.log(e);
            }
        },
        close() {
            this.$emit('close', null)
        },

        addItem(item) {
            this.$emit('close', item)
        },
        verifyIfExist(item) {
            let exist = this.importItems.filter((val) => val.id === item.id);
            return exist.length > 0;
        },




    },
};
</script>