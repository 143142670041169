<template>
  <v-dialog v-model="dialog" persistent max-width="680">
    <ValidationObserver v-slot="{ invalid }" ref="form">
      <v-card v-if="product" :key="key">
        <v-app-bar flat color="primary">
          <v-toolbar-title class="text-h6 white--text pl-0">
            {{ product.name }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-title class="white--text font-weight-regular pl-0">
            <template v-if="!viewPriceChange()">
              {{ $filters.currency(getProductPrice() * quantity) }}
            </template>
          </v-toolbar-title>
          <v-btn
            color="white"
            icon
            @click="$emit('cancel')"
            :title="$t('Cancel')"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-app-bar>

        <v-card-text style="max-height: 60vh; overflow-y: auto">
          <v-row class="pa-2 fill-height">
            <v-col lg="4" class="pt-0 text-center text-lg-left">
              <v-list-item-avatar
                tile
                size="175"
                class="rounded-lg"
                color="grey"
              >
                <v-img height="100%" :src="getPathProduct(product)"></v-img>
              </v-list-item-avatar>
            </v-col>
            <v-col lg="8" cols="12" class="pt-2 pb-0">
     
              <ng-select-options
                v-if="$pos && $pos.priceLists"
                v-model="currentPriceList"
                :items="$pos.priceLists"
                @change="changePriceList"
                :clearable="false"
                :flat="false"
                :filled="false"
                dense
                :outlined="true"
                :full-width="true"
              ></ng-select-options>

              <v-select
                v-model="discount"
                :items="discountRates"
                :label="$t('discounts')"
                @change="changePriceList"
                dense
                outlined
              >
              </v-select>

              <div class="d-flex justify-space-between">
                <ng-number-keyboard
                  v-model="quantity"
                  :label="$t('quantity')"
                  rules="required"
                  :filled="false"
                  dense
                  show-required="true"
                  id="quantity-field"
                ></ng-number-keyboard>
                <v-btn
                  small
                  color="red"
                  dark
                  fab
                  class="mx-2"
                  @click="minus"
                  :title="$t('decrease')"
                >
                  <v-icon>mdi-minus</v-icon>
                </v-btn>
                <v-btn
                  small
                  color="success"
                  dark
                  fab
                  @click="quantity++"
                  :title="$t('increase')"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </div>
            </v-col>

            <v-col cols="12" md="6" class="mt-0 pt-0 pb-0">
              <ng-number-keyboard
                v-model="price"
                :label="$t('price')"
                :filled="false"
                dense
                show-required="true"
                rules="required"
                rows="3"
                @change="changePrice"
                v-if="viewPriceChange()"
              ></ng-number-keyboard>
            </v-col>

            <v-col cols="12" class="py-0">
              <ng-textarea-keyboard
                v-model="note"
                :label="$t('note')"
                :filled="false"
                dense
                show-required="true"
                rows="4"
              ></ng-textarea-keyboard>
            </v-col>

            <v-col
              cols="12"
              v-for="(vr, index) in variants"
              :key="vr.id"
              class="pt-0"
            >
              <h4 class="mb-1">{{ vr.name }}</h4>
              <v-list-item-group
                v-model="variantsItem[vr.id]"
                class="d-flex flex-row flex-wrap"
              >
                <template
                  v-for="(opt, i) in vr.options"
                 
                 
                >
                <div  class="set-variant rounded pa-0 d-flex"  :key="i" v-if="!isOptionDisabled(opt, vr, index)">
                  <v-list-item
                  
                    :value="opt.id"
                   
                    @click="selectVariant(opt, vr)"
                    
                    style="border: 1px solid #ccc"
                  >
                    <template v-slot:default="{ active }">
                      <span v-if="!active" class="text-left grey--text">{{
                        opt.name
                      }}</span>
                      <span v-else class="green--text">{{ opt.name }}</span>
                      <v-list-item-action>
                        <v-icon right v-if="!active" color="grey lighten-2"
                          >mdi-check-circle-outline
                        </v-icon>
                        <v-icon right v-else color="green darken-3">
                          mdi-check-circle
                        </v-icon>
                      </v-list-item-action>
                    </template>
                  </v-list-item>
                </div>
                </template>
              </v-list-item-group>

              <!-- <v-list-item-group v-model="variantsItem[vr.id]" color="primary" class="d-flex flex-row flex-wrap">
                <div v-for="(opt, i) in vr.options" :key="i" class="set-variant">
                  <v-list-item :value="opt.id" :key="i" @click="selectVariant(opt, vr)" style="border: 1px solid #ccc"
                    class="cart-selection-variant-option">
                    <template v-slot:default="{ active }">
                      <span class="text-left">{{ opt.name }}</span>
                      <v-list-item-action>
                        <v-icon v-if="!active" color="grey lighten-3">mdi-check-circle-outline
                        </v-icon>
                        <v-icon v-else color="green darken-3">
                          mdi-check-circle
                        </v-icon>
                      </v-list-item-action>
                    </template>
                  </v-list-item>
                </div>
              </v-list-item-group> -->
            </v-col>

            <v-col cols="12" class="pt-0" v-for="opt in options" :key="opt.id">
              <CartSectionProductSaleOptions
                :option="opt"
                @selectedOption="selectedOption"
                :itemLine="itemLine"
                :currentList="getPriceListId()"
              ></CartSectionProductSaleOptions>
            </v-col>
            <!--v-col cols="12" style="color: red">
                    <div v-for="(msg, index) in messages" :key="index">
                  <span>{{msg.message}}</span>
                </div>
                </v-col-->
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            dark
            outlined
            color="primary"
            @click="$emit('cancel')"
            :title="$t('Cancel')"
          >
            {{ $t("Cancel") }}
          </v-btn>
          <v-btn
            color="primary"
            @click="update"
            :disabled="invalid"
            :title="$t('accept')"
          >
            {{ $t("accept") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </ValidationObserver>
  </v-dialog>
</template>
<style scoped>
.v-item--active {
  border: 1px solid rgb(0, 100, 150) !important;
  font-size: 1.2em !important;
  font-weight: bold;
}

.cart-selection-variant-option {
  border: 1px solid #ccc;
  max-width: 25%;
}
</style>
<script>
import CartSectionProductSaleOptions from "./CartSectionProductSaleOptions";
export default {
  name: "CartSectionProductOptions",
  components: { CartSectionProductSaleOptions },
  props: ["itemLine", "currentProduct", "open", "dialog", "priceList"],
  data: () => ({
    //prices: null
    //isVisible: false
    discountRates: [0, 10, 20, 30, 40, 50],
    discount: 0,
    quantity: 1,
    options: null,
    optionsItem: {},
    variants: null,
    variantsItem: {},
    variant: null,
    productVariant: null,
    key: 0,
    note: "",
    product: null,
    price: 0,
    currentPriceList: null,
    changePriceListFromForm: false,
    priceIsChange: false,
  }),
  async mounted() {
    this.clean();
    await this.loadConfig();

    if (this.priceList) {
      this.currentPriceList = {
        id: this.priceList.id,
        name: this.priceList.name,
      };
    }
    if (this.itemLine && this.itemLine.price_list_id) {
      for (const priceList of this.$pos.priceLists) {
        if (this.itemLine.price_list_id == priceList.id) {
          this.currentPriceList = { id: priceList.id, name: priceList.name };
          break;
        }
      }
    }
    if (this.itemLine) {
      if (this.itemLine.percentage_discount) {
        this.discount = this.itemLine.percentage_discount;
      }
      if (this.itemLine.unit_price) {
        this.price = this.itemLine.unit_price;
      }
    } else {
      if (this.product) {
        this.getProductPrice();
      }
    }
  },

  methods: {
    isOptionDisabled(opt, vr, index){
   
      if(index > 0){
        let prevs = []
        for(let i = 0; i < index; i++){
          let prev = this.variants[i]
          if(this.variantsItem[prev.id]){
            prevs.push(this.variantsItem[prev.id])
          }
        }
        
        if(prevs && prevs.length > 0){          
              prevs.push(opt.id)
              let productVariant = this.$db.containsOptions(this.product.id, prevs);
              if (!productVariant) {
                return true;
              }
        }
      }
      
      return false;
    },
    getProductPrice() {
      if (this.itemLine && !this.changePriceListFromForm) {
        this.price = this.$pos.getSalePrice(
          this.product,
          this.discount,
          this.getPriceListId(),
          this.productVariant?.id
        );
        if (this.discount) {
          return this.$pos.getSalePrice(
            this.product,
            this.discount,
            this.getPriceListId(),
            this.productVariant?.id
          );
        } else {
          return this.price;
        }
      } else {
        this.price = this.$pos.getSalePrice(
          this.product,
          this.discount,
          this.getPriceListId(),
          this.productVariant?.id
        );
        return this.price; //this.$pos.getSalePrice(this.product, this.discount, this.getPriceListId())
      }
    },
    changePriceList() {
      this.changePriceListFromForm = true;
      if (this.currentPriceList) {
        //return this.currentPriceList.id
        this.price = this.$pos.getSalePrice(
          this.product,
          0,
          this.getPriceListId(),
          this.productVariant?.id
        );
      }
    },
    getPriceListId() {
      if (this.currentPriceList) {
        return this.currentPriceList.id;
      }
      return null;
    },
    clean() {
      this.key++;
      this.product = this.currentProduct;
      this.variants = null;
      this.variantsItem = {};
      this.variant = null;
      this.options = null;
      this.optionsItem = {};
      this.note = "";
      this.loadConfig();
      this.priceIsChange = false;
      this.key++;
    },
    minus() {
      if (this.quantity > 1) {
        this.quantity--;
      }
    },
    selectVariant(opt, vr) {
      setTimeout(() => {
        this.checkConstrains();
      }, 200);
    },
    /* selectOption(option, opt){
        this.checkConstrains();
      },*/
    selectedOption(evt) {
      this.optionsItem[evt.id] = evt.option;
    },
    async checkConstrains() {
      //check variants

      if (this.variants) {
        let allVariants = true;
        for (const v of this.variants) {
          if (!this.variantsItem[v.id]) {
            allVariants = false;
            this.$root.$emit("error", {
              field: v.id,
              message: "Debe seleccionar al menos una opción de: " + v.name,
            });
          } else {
            this.$root.$emit("valid", { field: v.id });
          }
        }
        if (allVariants) {
          let founded = await this.selectedVariant();
          if (!founded) {
            alert("Esta variante no existe");
            this.variantsItem = [];
            allVariants = false;
          } else {
            return true;
          }
        }
        //this.key++;
        return false;
      } else {
        return true;
      }
    },
    async selectedVariant() {
      const options = [];
      for (const i in this.variantsItem) {
        options.push(this.variantsItem[i]);
      }
      console.log(options)
      this.productVariant = this.$db.searchVariant(this.product.id, options);
      if (!this.productVariant) {
        return false;
      }
      return this.productVariant;
    },
    async loadConfig() {
      if (this.itemLine) {
        this.quantity = this.itemLine.quantity;
        this.product = await this.$db.getProduct(this.itemLine.product.id);
        this.note = this.itemLine.note;
      }

      if (this.product.type == "variant") {
        await this.loadVariants();
      }
      if (this.product.sale_options) {
        await this.loadOptions();
      }
    },
    async update() {
      let check = await this.checkConstrains();
      if (!check) {
        return;
      }
      console.log(this.productVariant, 'update product detail....')
      let opts = {
        itemLine: this.itemLine,
        variant: this.productVariant,
        quantity: this.quantity,
        note: this.note,
        options: this.optionsItem,
        discount: this.discount,
        new_price: -1,
        bill_price_list_id: this.currentPriceList.id,
      };
      if (this.priceIsChange) {
        opts.new_price = this.price;
      } else {
        let price = this.$pos.getSalePrice(
          this.product,
          0,
          this.getPriceListId(),
          this.productVariant?.id
        );
        opts.new_price = price;
      }
      this.$emit("selectedOptions", opts);
    },
    getPathProduct(item) {
      if (item) {
        return this.$http.getBaseUrlImagesProducts() + item.image;
      }
      return "@/assets/product-blank.png";
    },
    getProductsByCategory(catId) {
      const prds = this.$db.searchProducts(
        {
          category_id: { $eq: catId },
        },
        30,
        "name"
      );
      return prds;
    },
    async loadOptions() {
      let allOptions = await this.$db.getSaleOptions();
      const optionsProduct = [];
      for (const v of this.product.sale_options) {
        for (const option of allOptions) {
          if (v.id == option.id) {
            optionsProduct.push({
              id: option.id,
              name: option.name,
              option_type: option.option_type,
              apply_price: option.apply_price,
              max_options: option.max_options,
              min_options: option.min_options,
              inv_category_id: option.inv_category_id,
              products: option.products,
              sale_options: option.sale_options,
            });
          }
        }
      }
      this.options = optionsProduct;
      if (this.itemLine && this.itemLine.options) {
        for (const i in this.itemLine.options) {
          this.optionsItem[i] = this.itemLine.options[i];
        }
        //currentVariant = await this.$db.adapter.product_variants.findOneById(this.itemLine.product_variant.id);
      }

      this.key++;
    },
    changePrice() {
      this.priceIsChange = true;
    },
    async loadVariants() {
      let allVariants = await this.$db.getVariants();

      const variantsProduct = [];
      this.variantsItem = {}; //variantes seleccionadas
      let currentVariant = null;
      if (
        this.itemLine &&
        this.itemLine.product_variant &&
        this.itemLine.product_variant.id
      ) {
        currentVariant = await this.$db.getProductVariants(
          this.itemLine.product_variant.id
        );
      }
      // se optienen la informacion de las opciones posibles configuradas para el producto de cada variante
      // si itemLine esta seteado se preseleccionan las opciones de variantes correspondientes
      for (const v of this.product.variants.variants) {
        for (const variant of allVariants) {
              if (v.id == variant.id) {
                const options = [];
                for (const opt of this.product.variants["v-" + v.id]) {
                  const idx = variant.options.findIndex((o) => o.id == opt.id);
                  if (idx >= 0) {
                    const option = variant.options[idx];
                    option.selected = false;
                    
                    if (
                      currentVariant &&
                      currentVariant.inv_variant_option_ids &&
                      currentVariant.inv_variant_option_ids.indexOf(opt.id) >= 0
                    ) {
                      option.selected = true;
                      this.variantsItem[v.id] = opt.id;
                    }
                    options.push(option);
                    if (currentVariant && currentVariant.length > 0) {
                      for (const vari of currentVariant) {
                        if (
                          vari &&
                          vari.inv_variant_option_ids &&
                          vari.inv_variant_option_ids.indexOf(opt) >= 0
                        ) {
                          this.variantsItem[v.id] = opt;
                        }
                      }
                    }
                  }
                }
                variantsProduct.push({
                  id: variant.id,
                  name: variant.name,
                  is_colour: variant.is_colour,
                  options: options.sort(function (a, b) {
                    return a.name.localeCompare(b.name);
                  }),
                });
              }
        }
      }
      this.variants = variantsProduct.sort(function (a, b) {
        return a.name.localeCompare(b.name);
      });
      this.checkConstrains();
      this.key++;
    },
    async viewPriceChange() {
      let active_price = false;
      let installation = this.$store.getters.installation;
      if (installation) {
        const cashRegister = await this.$db.getCashRegister(
          installation.cash_id
        );
        if (cashRegister) {
          if (cashRegister.config) {
            if (cashRegister.config.pos_active_price_change) {
              active_price = cashRegister.config.pos_active_price_change;
            }
          }
        }
      }
      if (active_price && this.itemLine != null) {
        return true;
      }
      return false;
    },
  },
};
</script>