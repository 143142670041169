<template>
  <v-dialog v-model="dialog" persistent max-width="680">
    <ValidationObserver v-slot="{ invalid }" ref="form">
      <v-card :key="key">
        <v-app-bar flat color="primary">
          <v-toolbar-title class="text-h6 white--text pl-0">
            {{ $t("add_coupon") }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="white" icon @click="$emit('close')" :title="$t('Cancel')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-app-bar>
        <v-card-text>
          <v-row class="pt-4">
            <v-col cols="12" sm="12">
              <ng-text-keyboard v-model="coupon" :label="$t('coupon')" rules="required"
                append-outer-icon="mdi-tag-multiple-outline"></ng-text-keyboard>

            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn dark color="primary" outlined @click="$emit('close')" :title="$t('Cancel')">
            {{ $t("Cancel") }}
          </v-btn>
          <v-btn color="primary" :disabled="invalid" @click="setCoupon()" :title="$t('apply_coupon')">
            {{ $t("apply_coupon") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </ValidationObserver>
  </v-dialog>
</template>
<style>
.bg_modal {
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)) 0% 0% / cover no-repeat;
}
</style>
<script>
import NgTextKeyboard from '../../../gui/NgTextKeyboard.vue';
const DEFAULT_ITEM = { reason: "" };
export default {
  components: { NgTextKeyboard },
  name: "CartCoupon",
  data: () => ({
    searched: false,
    key: 0,
    item: JSON.parse(JSON.stringify(DEFAULT_ITEM)),
    coupon: ''
  }),
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    order: Object,
  },
  mounted() {
    this.coupon = this.order.getNote();
  },
  methods: {
    selected() {
      const obj = JSON.parse(JSON.stringify(this.item));
      this.clear();
      this.searched = false;
      this.$emit("selected", obj);
      this.key++;
    },
    cancel() {
      this.clear();
      this.searched = false;
      this.key++;
      this.$emit("cancel");
    },
    clear() {
      this.item = JSON.parse(JSON.stringify(DEFAULT_ITEM));
    },
    setNote() {
      this.$pos.setNote(this.order, this.note);
      this.$emit("cancel");
    }
  },
};
</script>