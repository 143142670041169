<template>
  <div>
    <v-menu v-model="menu" :close-on-content-click="false" :nudge-width="260" max-height='50vh' offset-y>
      <template v-slot:activator="{ on, attrs }">

        <v-row>
          <v-col cols="12">
            <v-btn block color="primary" class="mb-2" v-bind="attrs" v-on="on" :title="titleFilter"><v-icon left
                color=white>mdi-filter</v-icon>{{ $t("filter") }}</v-btn>
            <v-btn block color="red lighten-4 red--text" :title="$t('clean_filters')" v-if="filterActive" @click="onCancel">{{ $t("clean_filters") }}</v-btn></v-col>
        </v-row>

      </template>

      <v-card>
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ $t("select_category") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-treeview dense hoverable selectable open-all return-object selected-color="primary" item-key="id"
          :items="categories" @input="clickOnNode" :selection-type="'independent'">
        </v-treeview>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text outlined @click="onCancel()">
            {{ $t("close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>
<script>
export default {
  name: 'CategoryFilter',
  components: {},
  data: () => ({
    categories: [],
    filterActive: false,
    menu: false,
    textFilter: '',
    titleFilter: ''
  }),
  props: ['is_mobile'],
  async mounted() {
    this.textFilter = this.$t('all');
    this.titleFilter = this.$t('have_applied_filters');
    this.categories = await this.$db.getAllCategories();
  },
  methods: {
    async onCancel() {
      this.menu = false;
      this.filterActive = false;
      this.textFilter = this.$t('all');
      this.titleFilter = this.$t('have_applied_filters');
      // se hace para resetear el contenido del arbol y volver a cargar          
      this.categories = [];
      this.categories = await this.$db.getAllCategories();
      this.$emit('change', []);

    },
    clickOnNode(node) {
      this.filterActive = true;
      this.menu = false;
      let text = '';
      let idsCategories = [];
      if (node.length > 0) {
        for (const element of node) {
          text = this.$t('have_applied_filters');
          idsCategories.push(element.id);
        }
      } else {
        text = this.$t('all');
        this.filterActive = false;
      }
      this.$emit('change', idsCategories);
      this.titleFilter = text;
      this.textFilter = text;
    }

  }
}
</script>