<template>
  <v-list-item  @click="selectProduct" :disabled="!hasPrice()">
              <v-list-item-avatar  tile  size="48" color="grey">
                <v-img  height="100%"  :src="getPathProduct(item)" ></v-img>
              </v-list-item-avatar> 
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  <span class="text--primary">{{$filters.currency($pos.getSalePrice(this.item,0, getPriceListId()), 0)}}</span> {{ item.description }}                
                </v-list-item-subtitle>                 
              </v-list-item-content>
              <v-list-item-action>
                <v-btn icon color="primary">
                  <v-icon >mdi-plus</v-icon>
                </v-btn>
              </v-list-item-action>
                                
            </v-list-item>
</template>
<script>

export default {
  name: 'ProductCardMobile',
  components: {  },
    data: () => ({
      prices: null
  		//isVisible: false
    }),
    props: ['item', 'priceList'],
   /* mounted(){
    },	*/
    methods:{
      selectProduct(){
        if(this.hasPrice()){
          this.$emit('select:product', this.item)
        }
      },
      hasPrice(){
        return this.$pos.getSalePrice(this.item, 0, this.getPriceListId()) > 0;
      },
      getPriceListId(){
        if(this.priceList){
          return this.priceList.id
        }
        return null
      },
      getPathProduct(item){
        if(item && this.$store.state.shared && this.$store.state.shared.company){
          //return this.$http.getBaseUrlImagesProducts() + this.$store.state.shared.company.id + '/' + item.image;
          return this.$http.getBaseUrlImagesProducts() + '/' + item.image;
        }
        return '@/assets/product-blank.png';
      },
      /*visibilityChanged(isVisible, entry){
      	this.isVisible = isVisible
      }*/
    }
}
</script>