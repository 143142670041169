<template>
  <v-dialog v-model="dialog" max-width="500" persistent>
    <v-card>

      <v-card-title class="text-center pa-3 mb-0 d-flex flex-column">
        <v-icon class="red lighten-5 pa-3 mb-3 rounded-pill" size="40" color="red">mdi-alert</v-icon>
        <h3 class="text-center mx-auto font-weight-regular red--text">{{ $t("title_modal_try_save_order") }}</h3>
      </v-card-title>

      <v-card-text align="center" class="py-3">
        {{ $t("text_modal_try_save_order") }}
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" outlined @click="dialog = false" small>
          <v-icon left>mdi-close</v-icon>{{ $t("cancel") }}</v-btn>
        <v-btn color="primary" @click="tryAgain()" small>
          <v-icon left>mdi-reload</v-icon> {{ $t("try") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    renderItem() {
      this.dialog = true;
    },
    tryAgain() {
      this.dialog = false;
      setTimeout(() => {
        this.$emit("try-save-order");
      }, 300);
    },
  },
};
</script>