<template>
  <div>
    <v-row class="px-1 mt-1">
      <v-col cols="12" class="ma-0 mt-1 pt-0 pb-0 mb-0" v-if="paymentMethod.manage_positive_balance">
        <v-alert color="blue" class="mt-0 mb-1" dark dense v-if="paymentMethod.manage_positive_balance">
          <v-row class="">
            <v-col class="text-left">
              <span class="title font-weight-medium">{{ $t('positive_balance') }}: </span>
            </v-col>
            <v-col class="text-right">
              <span class="title font-weight-regular">{{ $filters.currency(positiveBalance, 0) }}</span>
            </v-col>
          </v-row>
        </v-alert>
      </v-col>
      <v-col cols="12" class="ma-0 mt-1 mb-0 pt-0 pb-0" v-if="paymentMethod.need_reference">
        <ng-number-keyboard :label="$t('reference')" rules="required" :filled="false" :dense="false" show-required="true"
          v-model="reference" :disabled="orderNoBalance()"></ng-number-keyboard>
      </v-col>
    </v-row>
    <v-row class="px-1 mt-1 mb-1">
      <v-col cols="12" class="ma-0 pt-0 ">
        <v-row class="px-1 mt-0">
          <v-col cols="4">
            <v-btn :small="is_mobile" block elevation="2" :large="!is_mobile" :disabled="orderNoBalance()"
              @click="addTotalPayment(paymentMethod, 50)" :title="$t('add') + ' ' + $filters.currency(50, 0)">
              {{ $filters.currency(50, 0) }}
            </v-btn>
          </v-col>
          <v-col cols="4">
            <v-btn :small="is_mobile" block elevation="2" :large="!is_mobile" :disabled="orderNoBalance()"
              @click="addTotalPayment(paymentMethod, 100)" :title="$t('add') + ' ' + $filters.currency(100, 0)">
              {{ $filters.currency(100, 0) }}
            </v-btn>
          </v-col>
          <v-col cols="4">
            <v-btn :small="is_mobile" block elevation="2" :large="!is_mobile" :disabled="orderNoBalance()"
              @click="addTotalPayment(paymentMethod, 200)" :title="$t('add') + ' ' + $filters.currency(200, 0)">
              {{ $filters.currency(200, 0) }}
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="px-1 mt-0">
          <v-col cols="4">
            <v-btn :small="is_mobile" block elevation="2" :large="!is_mobile" :disabled="orderNoBalance()"
              @click="addTotalPayment(paymentMethod, 500)" :title="$t('add') + ' ' + $filters.currency(500, 0)">
              {{ $filters.currency(500, 0) }}
            </v-btn>
          </v-col>
          <v-col cols="4">
            <v-btn :small="is_mobile" block elevation="2" :large="!is_mobile" :disabled="orderNoBalance()"
              @click="addTotalPayment(paymentMethod, 1000)" :title="$t('add') + ' ' + $filters.currency(1000, 0)">
              {{ $filters.currency(1000, 0) }}
            </v-btn>
          </v-col>
          <v-col cols="4">
            <v-btn :small="is_mobile" block elevation="2" :large="!is_mobile" :disabled="orderNoBalance()"
              @click="addTotalPayment(paymentMethod, 2000)" :title="$t('add') + ' ' + $filters.currency(2000, 0)">
              {{ $filters.currency(2000, 0) }}
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="px-1 mt-0">
          <v-col cols="4">
            <v-btn :small="is_mobile" block elevation="2" :large="!is_mobile" :disabled="orderNoBalance()"
              @click="addTotalPayment(paymentMethod, 5000)" :title="$t('add') + ' ' + $filters.currency(5000, 0)">
              {{ $filters.currency(5000, 0) }}
            </v-btn>
          </v-col>
          <v-col cols="4">
            <v-btn :small="is_mobile" block elevation="2" :large="!is_mobile" :disabled="orderNoBalance()"
              @click="addTotalPayment(paymentMethod, 10000)" :title="$t('add') + ' ' + $filters.currency(10000, 0)">
              {{ $filters.currency(10000, 0) }}
            </v-btn>
          </v-col>
          <v-col cols="4">
            <v-btn :small="is_mobile" block elevation="2" :large="!is_mobile" :disabled="orderNoBalance()"
              @click="addTotalPayment(paymentMethod, 20000)" :title="$t('add') + ' ' + $filters.currency(20000, 0)">
              {{ $filters.currency(20000, 0) }}
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="px-1 mt-0">
          <v-col cols="4">
            <v-btn :small="is_mobile" block elevation="2" :large="!is_mobile" :disabled="orderNoBalance()"
              @click="addTotalPayment(paymentMethod, 50000)" :title="$t('add') + ' ' + $filters.currency(50000, 0)">
              {{ $filters.currency(50000, 0) }}
            </v-btn>
          </v-col>
          <v-col cols="4">
            <v-btn :small="is_mobile" block elevation="2" :large="!is_mobile" :disabled="orderNoBalance()"
              :title="$t('add') + ' ' + $filters.currency(100000, 0)" @click="addTotalPayment(paymentMethod, 100000)">
              {{ $filters.currency(100000, 0) }}
            </v-btn>
          </v-col>
          <v-col cols="4">
            <v-btn :small="is_mobile" block elevation="2" :large="!is_mobile" :disabled="orderNoBalance()"
              @click="cleanTotalPayment(paymentMethod)"
              :title="$t('erase')"><v-icon>mdi-backspace-outline</v-icon></v-btn>
          </v-col>
        </v-row>
        <v-row class="px-1 mt-0">
          <v-col cols="12" sm="12" md="12" class="ma-0 pb-0">
            <v-alert color="grey lighten-4" dense class="ma-0 black--text">
              <v-row>
                <v-col class="text-left">
                  <span class="title font-weight-medium">{{ $t('amount') }}: </span>
                </v-col>
                <v-col class="text-right">
                  <span class="title font-weight-regular">
                    {{ $filters.currency(totalPayment, 0) }}
                  </span>
                </v-col>
              </v-row>
            </v-alert>
          </v-col>
          <v-col cols="12" sm="12" md="12" class="ma-0">
            <v-alert color="green" dark outlined dense class="ma-0">
              <v-row class="green--text">
                <v-col class="text-left">
                  <span class="title font-weight-medium">{{ $t('change') }}: </span>
                </v-col>
                <v-col class="text-right">
                  <span class="title font-weight-regular">
                    {{ $filters.currency(this.change, 0) }}
                  </span>
                </v-col>
              </v-row>
            </v-alert>
          </v-col>
        </v-row>
        <v-row class="px-1 mt-0">
          <v-col cols="8">
            <v-btn color="green" class="white--text" :disabled="orderNoBalance()" block elevation="0"
              :title="$t('full_payment')" large @click="payComplete()">{{ $t('full_payment') }}</v-btn>
          </v-col>
          <v-col>
            <v-btn color="primary" :disabled="totalPayment < 1 || order.getBalance() == 0" block elevation="0" large
              @click="addPayment()">
              <v-icon>mdi-check-outline</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>
<script>
const DEFAULT_ITEM = { id: '', name: '', last_name: '', email: '', identification: '', phone: '', address: '' };
export default {
  name: 'CartPaymentsKeyboard',
  data: () => ({
    isPayCard: true,
    searched: false,
    totalPayment: 0,
    positiveBalance: 0,
    reference: null,
    change: 0
  }),
  props: {
    paymentMethod: {
      type: Object,
      default: null,
    },
    order: Object,
    is_mobile: Boolean
  },
  //props: ['customer'],

  mounted() {
    /*if(this.customer){
      setTimeout(() => {


      this.item = JSON.parse(JSON.stringify(this.customer));
      this.searched = true;
      this.key++;
      }, 250)
    }*/
  },
  methods: {
    addTotalPayment(paymentMethod, value) {
      this.totalPayment += value;
      if (paymentMethod.manage_change) {
        this.calculateChange();
      }
    },
    async addPayment() {
      let value = this.totalPayment;
      if (this.totalPayment > this.order.getBalance()) {
        value = this.order.getBalance();
      }
      let turn = this.$store.getters.turn;
      this.$pos.addPayment(
        this.order,
        {
          _id: new Date().getTime(),
          payment_method: this.paymentMethod,
          date: this.$filters.datetime((new Date())),
          value: value,
          acc_income_id: null,
          reference: this.reference,
          bill_cash_register_id: this.order.getBillCashRegisterId(),
          bill_cash_register_turn_id: turn.id,
          change: this.change
        });
      this.totalPayment = 0;
    },
    calculateChange() {
      const change = this.totalPayment - this.order.getBalance();
      if (change < 0) {
        this.change = 0;
      } else {
        this.change = change;
      }

    },
    cleanTotalPayment(paymentMethod) {
      this.totalPayment = 0;
      if (paymentMethod.manage_change) {
        this.calculateChange();
      }
    },
    payComplete() {
      this.totalPayment = this.order.getBalance();
    },
    cancel() {
      this.clear();
      this.searched = false;
      this.key++;
      this.$emit('cancel');
    },
    orderNoBalance() {
      if (this.order.getBalance() == 0) {
        return true;
      }
      return false;
    }
  }
}
</script>