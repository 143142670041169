<template>
    <v-dialog v-model="dialog" width="576" style="background-color: white">
        <v-card class="pa-3">
            <v-card-text class="pa-3" style="overflow-y: auto; max-height: calc(100vh - 200px)">
                <v-row v-if="item">
                    <v-col cols="12">
                        <v-row class="d-flex align-center blue lighten-5 rounded pa-1">
                            <v-icon color="white" class="pa-2 rounded blue lighten-1 me-2">mdi-pill</v-icon>
                            <h4>Información básica del medicamento:</h4>
                        </v-row>
                        <v-row>
                            <v-col cols="12" lg="6" class="list-mobile-field">
                                <h4>{{ $t("code") }}:</h4>
                                {{ item.code }}
                            </v-col>
                            <v-col cols="12" lg="6" class="list-mobile-field">
                                <h4>{{ $t("ean") }}:</h4>
                                {{ item.ean }}
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" lg="6" class="list-mobile-field">
                                <h4>{{ $t("name") }}:</h4>
                                {{ item.name }}
                            </v-col>
                            <v-col cols="12" lg="6" class="list-mobile-field">
                                <h4>{{ $t("provider_name") }}:</h4>
                                {{ item.provider_name }}
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" lg="6" class="list-mobile-field">
                                <h4>{{ $t("generic_brand") }}:</h4>
                                {{ item.generic_brand }}
                            </v-col>
                            <v-col cols="12" lg="6" class="list-mobile-field">
                                <h4>{{ $t("trademark") }}:</h4>
                                {{ item.trademark }}
                            </v-col>
                        </v-row>

                        <v-row class="d-flex align-center blue lighten-5 rounded pa-1">
                            <v-icon color="white" class="pa-2 rounded blue lighten-1 me-2">mdi-pill</v-icon>
                            <h4>Existencias:</h4>
                        </v-row>
                        <v-row>
                            <v-col cols="12"  class="list-mobile-field">
                        <v-simple-table fixed-header style="max-height:450px">
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-left">{{ $t("Bodega") }}</th>
                                     
                                        <th >
                                            {{ $t("Cantidad") }}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="item in items" :key="item.id">
                                        
                                        <td>{{ item.subsidiary.name }}</td>
                                        <td>{{  item.quantity }}</td>
                                     
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                        </v-col>
                        </v-row>


                        <v-row class="d-flex align-center blue lighten-5 rounded pa-1 mt-4">
                            <v-icon color="white" class="pa-2 rounded blue lighten-1 me-2">mdi-pill</v-icon>
                            <h4>Información Técnica:</h4>
                        </v-row>
                        <v-row>
                            <v-col cols="12" lg="6" class="list-mobile-field">
                                <h4>{{ $t("active_ingredient") }}:</h4>
                                {{ item.active_ingredient }}
                            </v-col>
                            <v-col cols="12" lg="6" class="list-mobile-field">
                                <h4>{{ $t("concentration") }}:</h4>
                                {{ item.concentration }}
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" lg="6" class="list-mobile-field">
                                <h4>{{ $t("content") }}:</h4>
                                {{ item.cm_content }}
                            </v-col>
                            <v-col cols="12" lg="6" class="list-mobile-field">
                                <h4>{{ $t("presentation") }}:</h4>
                                {{ item.presentation }}
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" lg="6" class="list-mobile-field">
                                <h4>{{ $t("pharmaceutical_form") }}:</h4>
                                {{ item.pharmaceutical_form }}
                            </v-col>
                            <v-col cols="12" lg="6" class="list-mobile-field">
                                <h4>{{ $t("therapy_class") }}:</h4>
                                {{ item.therapy_class }}
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" lg="6" class="list-mobile-field">
                                <h4>{{ $t("way_administration") }}:</h4>
                                {{ item.way_administration }}
                            </v-col>
                            <v-col cols="12" lg="6" class="list-mobile-field">
                                <h4>{{ $t("storage_temperature") }}:</h4>
                                {{ item.storage_temperature }}
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" lg="6" class="list-mobile-field">
                                <h4>{{ $t("fractionable") }}:</h4>
                                {{ $filters.boolean(item.fractionable) }}
                            </v-col>
                            <v-col cols="12" lg="6" class="list-mobile-field">
                                <h4>{{ $t("bonus_product") }}:</h4>
                                {{ $filters.boolean(item.bonus_product) }}
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" lg="6" class="list-mobile-field">
                                <h4>{{ $t("requires_formula") }}:</h4>
                                {{ $filters.boolean(item.requires_formula) }}
                            </v-col>
                            <v-col cols="12" lg="6" class="list-mobile-field">
                                <h4>{{ $t("pum") }}:</h4>
                                {{ $filters.boolean(item.pum) }}
                            </v-col>
                        </v-row>

                        <v-row class="d-flex align-center blue lighten-5 rounded pa-1">
                            <v-icon color="white" class="pa-2 rounded blue lighten-1 me-2">mdi-pill</v-icon>
                            <h4>Reglamento Invima:</h4>
                        </v-row>
                        <v-row>
                            <v-col cols="12" lg="6" class="list-mobile-field">
                                <h4>{{ $t("invima_code") }}:</h4>
                                {{ item.invima_code }}
                            </v-col>
                            <v-col cols="12" lg="6" class="list-mobile-field">
                                <h4>{{ $t("invima_expiration_date") }}:</h4>
                                {{ $filters.date(item.invima_expiration_date) }}
                            </v-col>
                        </v-row>
                        <!--v-row class="d-flex align-center blue lighten-5 rounded pa-1">
                <v-icon color="white" class="pa-2 rounded blue lighten-1 me-2"
                  >mdi-pill</v-icon
                >
                <h4>Control Directo:</h4>
              </v-row>
              <v-row>
                <v-col cols="12" lg="6" class="list-mobile-field">
                  <h4>{{ $t("dc_unit_price") }}:</h4>
                  {{ $filters.currency(item.dc_unit_price) }}
                </v-col>
                <v-col cols="12" lg="6" class="list-mobile-field">
                  <h4>{{ $t("dc_publication") }}:</h4>
                  {{ item.dc_publication }}
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" lg="6" class="list-mobile-field">
                  <h4>{{ $t("tax") }}:</h4>
                  {{ item.tax }}
                </v-col>
              </v-row-->
                        <v-row class="d-flex align-center blue lighten-5 rounded pa-1">
                            <v-icon color="white" class="pa-2 rounded blue lighten-1 me-2">mdi-pill</v-icon>
                            <h4>Categorización</h4>
                        </v-row>
                        <v-row>
                            <v-col cols="12" lg="6" class="list-mobile-field">
                                <h4>{{ $t("group") }}:</h4>
                                {{ item.group }}
                            </v-col>
                            <v-col cols="12" lg="6" class="list-mobile-field">
                                <h4>{{ $t("category") }}:</h4>
                                {{ item.category }}
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" lg="6" class="list-mobile-field">
                                <h4>{{ $t("subcategory") }}:</h4>
                                {{ item.subcategory }}
                            </v-col>
                            <v-col cols="12" lg="6" class="list-mobile-field">
                                <h4>{{ $t("classification") }}:</h4>
                                {{ item.classification }}
                            </v-col>
                        </v-row>
                        <v-row class="d-flex align-center blue lighten-5 rounded pa-1">
                            <v-icon color="white" class="pa-2 rounded blue lighten-1 me-2">mdi-pill</v-icon>
                            <h4>Descripciones</h4>
                        </v-row>

                        <v-row>
                            <v-col cols="12" lg="6" class="list-mobile-field">
                                <h4>{{ $t("atci_description") }}:</h4>
                                {{ item.atci_description }}
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" lg="6" class="list-mobile-field">
                                <h4>{{ $t("atcii_description") }}:</h4>
                                {{ item.atcii_description }}
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" lg="6" class="list-mobile-field">
                                <h4>{{ $t("atciii_description") }}:</h4>
                                {{ item.atciii_description }}
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row no-gutters v-else>
                    <v-col cols="12" class="list-mobile-field text-center">
                        Consultando Información ...
                    </v-col>
                    <v-col cols="12" class="list-mobile-field text-center">
                        <v-progress-linear indeterminate color="primary"></v-progress-linear>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions class="d-flex justify-end">
                <v-row>
                    <v-col cols="6" v-if="add">
                        <v-btn color="secondary" outlined @click="closeModal()" small block class="btn--close">
                            <v-icon small>mdi-close</v-icon>{{ $t("Cerrar") }}
                        </v-btn>
                    </v-col>
                    <v-col cols="6" v-if="add">
                        <v-btn color="primary" @click="addItem()" block small>
                            <v-icon small>mdi-package-variant-plus</v-icon>{{ $t("Añadir") }}
                        </v-btn>
                    </v-col>
                    <v-col cols="12" v-else>
                        <v-btn color="secondary" outlined @click="closeModal()" small block class="btn--close">
                            <v-icon small>mdi-close</v-icon>{{ $t("Cerrar") }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    data() {
        return {
            dialog: false,
            item: null,
            add: true,
            product: null,
            items: []
        };
    },
    methods: {
        renderItem(medication, add = true) {
            this.dialog = true;
            this.add = add;
            this.getInfo(medication);
        },
        async getInfo(medication) {
            this.product = medication;
            this.item = medication.info_medication;
            this.$http.get('api/v1/stock', { product: { id: medication.id } }).then((response) => {
                if (response.success) {
                    this.items = response.items;
                }
            })
        },
        addItem() {
            this.$emit("add:item", this.product);
            this.closeModal();
        },
        closeModal() {
            this.item = null;
            this.add = true;
            this.dialog = false;
        },
    },
};
</script>

<style>
.__list-item-icon {
    background: #e3f2fd !important;
    border-radius: 50%;
    padding: 12px;
}
</style>