<template>
  <div>
    <v-dialog v-model="open" persistent max-width="768">

      <v-card class="overflow-hidden">
        <v-app-bar flat color="success">
          <v-toolbar-title class="text-h6 white--text pl-0">
            {{ $t("open_turn") }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="white" icon @click="$emit('cancel')" :title="$t('Cancel')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-app-bar>

        <v-card-text class="pa-2">
          <ValidationObserver v-slot="{ invalid }" ref="form">
            <form class="v-form">
              <v-row class="pa-3 pb-0 mb-0" :key="key">
                <v-col cols="12">
                  <ng-number-keyboard v-model="item.starting_amount" :label="$t('starting_amount')" rules="required"
                    :filled="false" :dense="false" show-required="true"></ng-number-keyboard>
                  <ng-textarea-keyboard v-model="item.observation" :label="$t('observation')" :filled="false"
                    :dense="false"></ng-textarea-keyboard>
                </v-col>
              </v-row>
            </form>
            <v-card-actions class="mt-0 pt-0">
              <v-col class="text-right ma-0 pa-0">
                <v-btn block large :title="$t('open_turn')" elevation="0" color="success" @click="openTurn()"
                  :disabled="invalid">
                  {{ $t("open_turn") }}
                </v-btn>
              </v-col>
            </v-card-actions>
          </ValidationObserver>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  name: "OpenTurn",
  components: {},
  props: ["open"],
  data: () => ({
    dialog: true,
    key: 0,
    item: { starting_amount: 0, observation: null, active: true, opened: true },
    app_version: null,
    installation: null
  }),
  mounted() {
    this.load();
    this.$store.commit('setDrawer', false)
  },
  methods: {
    openHelp() {
      window.open(process.env.VUE_APP_BASECONFIG_URL_HELP, "_blank");
    },
    async openTurn() {
      this.installation = await this.$db.getInstallation();
      this.item['cash_register'] = { id: this.installation.cash_id };
      this.item['subsidiary'] = this.installation.subsidiary_id;
      this.item['opening_date'] = this.$filters.datetime((new Date()));
      this.item['created_at'] = this.$filters.datetime((new Date()));
      let opened = await this.$pos.syncTurn(this.item);
      if(opened){
        this.$emit('opened', this.item)
        this.$pos.createOrder();
      }
    },
    load() {
      setTimeout(async () => {
        this.app_version = process.env.VUE_APP_BASECONFIG_APP_VERSION;

      }, 50);
    },
  },
};
</script>
